import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataTableColumn } from '@interfaces/data-table-column';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';

@Component({
  selector: 'app-validation-distributors-page',
  templateUrl: './validation-distributors-page.component.html',
  styleUrls: ['./validation-distributors-page.component.scss']
})
export class ValidationDistributorsPageComponent {

  @ViewChild('validationTabs') _tabs: ElementRef;

  @ViewChild('distributorsTable') _distributorsDataTable: FlatDataTableComponent;

  distributorsColumns: DataTableColumn[];
  distributorsGridRows: object[];
  data: object[];
  fileExtentions = ".xls, .csv, .xlsx";
  validationType: string;


  onTabChanged() {
      this.validationType = "distributors";
  }

  uploaddistributorsValidationFiles(event) {
    const file: File = event;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: WorkBook = read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.data = utils.sheet_to_json(ws);
      // populates data needed for data table.
      this.distributorsColumns = [];
      this.distributorsGridRows = [];
      Object.keys(this.data[0] || {}).forEach(key => {
        this.distributorsColumns.push({
          field: key, label: key
        });
      });
      Object.values(this.data || {}).forEach((value) => {
        this.distributorsGridRows.push(value);
      });
    };
  }
}
