
<div class="container">
    <div class="comp1">
      <app-file-upload-input id="ticketListsFileUpload" (uploadFile)="uploadedFile($event)"
        [fileExtentions]="fileExtentions" />
    </div>
    <div class="comp2"><wdpr-button #runAllBtn (click)="runFiles()" theme="secondary"
        [disabled]="validationData===undefined">
        <i class="di di-media-play-button"></i>Run All
      </wdpr-button>
    </div>
  </div>
