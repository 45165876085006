
import { Component, OnInit } from '@angular/core';
import { TradeUtilityService } from '@services/ttc/trade-utility.service';
import { firstValueFrom } from 'rxjs';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';

@Component({
  selector: 'app-channel-managers-page',
  templateUrl: './channel-managers-page.component.html',
  styleUrls: ['./channel-managers-page.component.scss']
})
export class ChannelManagersPageComponent implements OnInit {

  channelManagers$: Promise<any>;

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService
  ) {

  }

  ngOnInit(): void {
    this.activityService.show("Retrieving Channel Managers");
    this.channelManagers$ = firstValueFrom(this.tus.getChannelManagers()).then((items) => {
      return items;
    }).finally(() => {
      this.activityService.hide();
    });
  }

}
