import { Component, Input, OnInit } from '@angular/core';
import { StopsellTask } from '@interfaces/stopsell-task';
import { StopsellTaskRequest } from '@app/core/interfaces/ttc/stopsell-task-request';
import { ActivityIndicatorService } from '@app/core/services/activity-indicator/activity-indicator.service';
import { TradeUtilityService } from '@app/core/services/ttc/trade-utility.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-stopsell-history',
  templateUrl: './stopsell-history.component.html',
  styleUrls: ['./stopsell-history.component.scss']
})
export class StopsellHistoryComponent implements OnInit {

  protected readonly JSON = JSON;

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService
  ) {
  }
  ngOnInit(): void {
    this.getAllStopsellTaskStatus();
  }

  @Input() type: string;

  stopsellTaskList: StopsellTask[] = [];
  stopsellTaskCount = 0;
  stopsellTaskStatus = [
    {
      value: 'IN_PROGRESS',
      text: 'IN_PROGRESS'
    },
    {
      value: 'INCOMPLETE',
      text: 'INCOMPLETE'
    },
    {
      value: 'FAILED',
      text: 'FAILED'
    },
    {
      value: 'COMPLETED',
      text: 'COMPLETED'
    }
  ];

  stopsellTaskStatusSelected = [
    {
      value: 'IN_PROGRESS',
      text: 'IN_PROGRESS'
    },
    {
      value: 'INCOMPLETE',
      text: 'INCOMPLETE'
    },
    {
      value: 'FAILED',
      text: 'FAILED'
    },
    {
      value: 'COMPLETED',
      text: 'COMPLETED'
    }
  ];

  stopsellStatus: string;

  stopsellStatusBoxPlaceholder(selected) {
    const options = {
      '0': 'Select Item(s)',
      '1': selected[0] ? selected[0].text : '',
      'default': `${selected.length} Items`
    };

    return (options[selected.length] || options['default']);
  }

  getAllStopsellTaskStatus() {

    const request: StopsellTaskRequest = {
      headers: {
        requestorType: 'TTCSpa',
      },
      params: {
        type: this.type
      }
    };

    this.activityService.show("Loading Stopsell History...");

    firstValueFrom(this.tus.getStopsellStatus(request))
      .then((stopsellResponse) => {

        stopsellResponse.map(item => {
          this.stopsellTaskList.push(item);
          this.stopsellTaskCount = this.stopsellTaskList.length;
          this.activityService.hide();
        });

    }).catch((reason) => {
      console.log(reason);
    }).finally(() => {
      this.activityService.hide();
    });

  }

  getStospSellStatusDetails(event) {
    this.stopsellTaskList = [];
    console.log(event);
    const taskList = (event.detail.value.map(v => v.value)).flatMap(((task) =>
      Array.isArray(task) ? task : [task]
    ));
    if (taskList.length == 4) {
      this.getAllStopsellTaskStatus();
    } else {
      this.getStopSellHistory(taskList);
    }
  }

  getStopSellHistory(taskList: any) {
    this.stopsellTaskCount = 0;

    try {
      this.activityService.show("Loading Stopsell History...");
      taskList.forEach(status => {
        const request: StopsellTaskRequest = {
          headers: {
            requestorType: 'TTCSpa'
          },
          params: {
            type: this.type,
            status: status
          }
        };

        firstValueFrom(this.tus.getStopsellStatus(request)).then((stopsellResponse) => {
          stopsellResponse.map(item => {
            this.stopsellTaskList.push(item);
            this.stopsellTaskCount = this.stopsellTaskList.length;
            this.activityService.hide();
          });
        });
      });
    } catch (error) {
      this.activityService.hide();
    } finally {

      this.activityService.hide();
    }

  }

}
