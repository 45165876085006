<wdpr-tabs selected-tab-index="0">
  <wdpr-tab id="tab-a0">Stopsell</wdpr-tab>
  <wdpr-tab id="tab-a1">History</wdpr-tab>
  <div slot="body">
    <div data-tab-index="0" tabindex="0" class="stopsell-main">
      <div class="title">
        <h2>Ticket Code Stopsell</h2>
      </div>
      <div class="stopsell-main-content">
        <wdpr-ticket-stopsell-form #wdprTicketStopsellForm class="header-wrapper">
          <div class="cm-header-wrapper">
            <div class="div-header-label"> Channel Manager *</div>
            <div class="select-wrapper">
              <wdpr-single-select id="cmSelectON" [options]="cmData"
                                  (item-selected)="onSelectCM($event)"
                                  placeholder="Select Channel Manager"></wdpr-single-select>
            </div>
          </div>
          <div class="dest-header-wrapper">
            <div class="div-header-label"> Destination *</div>
            <div class="select-wrapper">
              <wdpr-single-select id="destSelectON"
                                  [options]="destData" (item-selected)="onSelectBrand($event)"
                                  placeholder="Select Destination"></wdpr-single-select>
            </div>
          </div>
          <div class="dest-header-wrapper">
            <div class="div-header-label"> Ticket Type *</div>
            <div class="select-wrapper">
              <wdpr-single-select id="ticketTypeSelectON"
                                  [options]="ticketType" (item-selected)="onSelectTicketType($event)"
                                  placeholder="Select Type"></wdpr-single-select>
            </div>
          </div>

          <div class="dest-header-wrapper">
            <div class="div-header-label"> Ticket Code *</div>
            <div class="select-wrapper">
              <wdpr-multi-select id="ticketCodeSelectON" checkbox="true"
                                 select-all="true" [options]="ticketCodes"
                                 (selected-changed)="onSelectTC($event)"
                                 placeholder="Select ticket code(s)"></wdpr-multi-select>
            </div>
          </div>

          <div class="submit-header-wrapper">
            <wdpr-button [disabled]="disableBtn" id="submitBtn" (click)="submitBtnOnClick()"> Place Ticket(s) on
              Stopsell
            </wdpr-button>
          </div>
        </wdpr-ticket-stopsell-form>
        <div lass="remark-content">
          <label class="stopsell-remark-label">Remarks *</label>
          <textarea rows="4" id="stopSellRemarkON" cols="80" class="stopsell-remark-textarea"
                    (change)="onRemarksInput($event)"
                    placeholder="Required remarks about why the stopsell is being used."></textarea>
        </div>
      </div>
      <div class="grid-wrapper">
        <app-pagination-data-table
          [columns]="columns"
          [rowData]="ticketCodeStopsellGridRows"
          [paginator]="true"
          [rows]="10"
          [totalRecords]="ticketCodeStopsellGridRows===undefined ? 0 : ticketCodeStopsellGridRows.length"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          [rowsPerPageOptions]="[10, 25, 50]"
        ></app-pagination-data-table>
      </div>

      <wdpr-modal #stopSellOnConfirm show-header-separator="true" hide-close-icon="true" size="small">
        <h3 slot="title" class="heavy">Ticket Code Stopsell Confirmation</h3>
        <div slot="body">
          <table class="confirm-table">
            <tr>
              <td>No of Distributors on Stopsell </td>
              <td> : {{distributorStopsellCount}}</td>
            </tr>
            <tr>
              <td>Channel Manager </td>
              <td> : {{channelManager}}</td>
            </tr>
            <tr>
              <td>Destination </td>
              <td> : {{destination}}</td>
            </tr>
            <tr>
              <td>Ticket Codes </td>
              <td><span>: </span>{{ticketCodesOnStopsell}}</td>
            </tr>
          </table>
          <div class="alertButtons">
            <wdpr-button slot="buttons" class="alertButton" id="cancelBtn" (click)="cancelBtnOnClick()">Close</wdpr-button>
          </div>
        </div>
      </wdpr-modal>
      <app-stopsell-pocket-panel  #PocketPanelComponent [title]="title" (saveStopSell)="showConfirmationModal()"
                                  [pocketPanelBody]="pocketPanelBodyStopsellON"></app-stopsell-pocket-panel>
    </div>
    <div data-tab-index="1" tabindex="1" class="stopsell-main">
      <div class="title">
        <h2>History </h2>
      </div>
      <div slot="body" class="history-wrapper">
        <app-stopsell-history #stopsellHistory [type]="type"/>
        </div>
    </div>
  </div>
</wdpr-tabs>

