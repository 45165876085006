import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StopsellPocketPanelComponent } from '@core/components/stopsell-pocket-panel/stopsell-pocket-panel.component';
import { TradeUtilityService } from '@services/ttc/trade-utility.service';
import { StopSellType } from "@services/ttc/stop-sell-type";
import { DistributorsRequest } from '@interfaces/ttc/distributors-request';
import { Distributor } from '@interfaces/distributor';
import { catchError } from "rxjs";
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { PackageComponentStopsellGridRow } from "@interfaces/package-component-stopsell-grid-row";
import { DataTableColumn } from '@interfaces/data-table-column';
import { StopSellDistributor } from '@interfaces/stopsell-distributor';

@Component({
  selector: 'app-stopsell-package-component-page',
  templateUrl: './stopsell-package-component-page.component.html',
  styleUrls: ['./stopsell-package-component-page.component.scss']
})
export class StopsellPackageComponentPageComponent implements OnInit {
  @ViewChild('dynamicTabs') dynamicTabs: ElementRef;

  @ViewChild('PocketPanelComponent') stopsellPocketPanelComponent: StopsellPocketPanelComponent;

  @ViewChild('stopSellOnConfirm') stopSellOnModal: ElementRef;

  selectedTabIndex = 0;
  columns: DataTableColumn[] = [];
  packageComponentStopSellGridRows: PackageComponentStopsellGridRow[];
  cmData: string[] = [];
  destData: string[] = [];
  packageCompData: string[] = [];
  destSelected: string;
  packageCompSelected: string[] = [];
  channelManagerSelected: string;
  disableBtn = true;
  disableDestDropdown = true;
  disablePackageCompDropdown = true;
  remarksEntered: any;
  distributorStopsellCount: number;
  channelManager: string;
  destination: string;
  packageComponent: string[] = [];
  title = "Package Component Stopsell";
  pocketPanelBodyStopsellON: string[] = [];
  pocketPanelBodyStopsellOff: string[] = [];
  distributorList: Distributor[] = [];
  stopsellFlag = false;
  popupText: string;
  chanelManagers;
  stopsellresp;
  type : string = StopSellType.PACKAGE_COMPONENT;

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService
  ) {
  }

  ngOnInit(): void {

    this.activityService.show("Retrieving Channel Managers...");
    this.tus.getChannelManagers()
      .subscribe((cmResponse) => {
        this.cmData = cmResponse.map(item => {
          return item.name;
        });
        this.chanelManagers = cmResponse.map(item => {
          return item;
        });
        this.activityService.hide();
      });

    this.packageCompData = ["Package Ticket","Meal Plan"];
    this._initDataTableData();
  }

  populateDistributors() {
    // Call TUS to populate the distributors list
    const request: DistributorsRequest = {
      headers: {
        'X-Fresh-Data': 'false',
        requestorType: 'TTCSpa',
        requestorId: this.channelManagerSelected,
        propertyBrand: this.destSelected
      },
      params: {
        field: 'pos'
      }
    };

    this.activityService.show("Retrieving Distributors...");
    this.tus.getDistributors(request)
      .subscribe((distributorsResponse) => {

        this.packageComponentStopSellGridRows = distributorsResponse.map(distributor => {
          this.distributorList.push(distributor);

          return {
            name: distributor.name.value,
            code: distributor.name.code
          };
        });

        this.activityService.hide();
      });
  }

  stopsellSubmit() {

    if (this.stopsellFlag) {
      this.pocketPanelBodyStopsellON = ["No of distributors being removed from stopsell : " + this.packageComponentStopSellGridRows.length,
      "Channel Manager : " + this.channelManagerSelected,
      "Destination : " + this.destSelected,
      "Package Component : " + this.packageCompSelected,
        "Are you sure you want to remove selected distributors from stopsell?"];
      this.popupText = "No of Distributors removed from Stopsell";
      this.stopsellPocketPanelComponent.openRightPanel();
    } else {
      this.pocketPanelBodyStopsellON = ["No of distributors being put on stopsell : " + this.packageComponentStopSellGridRows.length,
      "Channel Manager : " + this.channelManagerSelected,
      "Destination : " + this.destSelected,
      "Package Component : " + this.packageCompSelected,
        "Are you sure you want to put selected distributors on stopsell?"];
      this.popupText = "No of Distributors on Stopsell ";
      this.stopsellPocketPanelComponent.openRightPanel();
    }
  }

  cancelBtnOnClick() {
    this.stopSellOnModal.nativeElement.open = false;
  }

  onSelectCM(event) {
    this.channelManagerSelected = event.detail.value;
    this.populateDropDowns();
    this.enableControls(this.channelManagerSelected, null, null, null);
  }

  populateDropDowns() {
    for (let i = 0; i < this.chanelManagers.length; i++) {
      if (this.chanelManagers[i].name === this.channelManagerSelected) {
        this.destData = this.mapDestinationList(this.chanelManagers[i].propertyBrands);
        break;
      }
    }
  }

  mapDestinationList(propertyBrands: [{ code: string }]): string[] {
    const resp: string[] = propertyBrands.map(item => item.code);

    return resp;
  }


  onSelectDest(event) {
    this.destSelected = event.detail.value;
    this.enableControls(this.channelManagerSelected,this.destSelected,null,null);
  }

  onSelectPackageComp(event) {
    this.packageCompSelected = [];
    if (event && event.detail && event.detail.value.length > 0) {
      this.packageCompSelected = event.detail.value.map(v => v.value);
    }

    this.populateDistributors();
    this.enableControls(this.channelManagerSelected,this.destSelected,this.packageCompSelected,null);
  }

  onRemarksInput(event) {
    this.remarksEntered = event.target.value;
    this.enableControls(this.channelManagerSelected,this.destSelected,this.packageCompSelected,this.remarksEntered);
  }

  enableControls(channelManager: string, destination: string, packageComp: string[], remarks: string) {
    if (channelManager) {
      this.disableDestDropdown = false;
    }
    if (destination && channelManager) {
      this.disablePackageCompDropdown = false;
    } else {
      this.disablePackageCompDropdown = true;
    }
    if (destination && channelManager &&
      packageComp && remarks) {
      if (this.distributorList.length > 0 && this.distributorList.at(0).propertyBrands[destination].pos.length > 0) {
        const stopsells = this.distributorList.at(0).propertyBrands[destination].pos.at(0).stopSells;
        if (stopsells != null) {
          stopsells.forEach(s => {
            if (s.type.includes('Package Component')) {
              this.stopsellFlag = true;
            }
          });
        }
      }
      this.disableBtn = false;
    } else {
      this.disableBtn = true;
    }
  }


  showConfirmationModalforOn() {
    this.performStopSell();
    this.distributorStopsellCount = this.packageComponentStopSellGridRows.length;
    this.destination = this.destSelected;
    this.channelManager = this.channelManagerSelected;
    this.packageComponent = this.packageCompSelected;
    this.stopSellOnModal.nativeElement.open = true;
  }

  performStopSell() {

    // Map Distributor Stop Sell Request
    const selectedBranchDistributors: StopSellDistributor[] = this.mapToStopSell(this.distributorList);

    const individualDistributors = selectedBranchDistributors.map((item => {
      return { distributors: [item] };
    }));

    // Call TUS
    this.activityService.show("Performing Stopsell...");
    this.tus.updateStopSell( individualDistributors,
    this.channelManagerSelected,
    this.destSelected,
    StopSellType.PACKAGE_COMPONENT,
    !this.stopsellFlag,
    this.remarksEntered,
    this.packageCompSelected
      )
    .pipe(
      catchError(error => {
        console.log('Error: ', error);
        this.activityService.hide();

        return [];
      })
    )
    .subscribe((cmResponse) => {
      this.stopsellresp = cmResponse.map(item => {
        return item;
      });
      this.activityService.hide();
    });
  }

  mapToStopSell(distributorList: Distributor[]): StopSellDistributor[] {
    return distributorList.map( dist => {
      return {
        name: { value: dist.name.code },
        pos: dist.propertyBrands != undefined ? dist.propertyBrands[this.destSelected].pos : []
      };
    });
  }

  selectTab(index: number): void {
    this.selectedTabIndex = index;
    this.dynamicTabs.nativeElement.setAttribute('selected-tab-index', `${index}`);
  }

  _initDataTableData() {

    this.columns = this.columns = [{
      field: 'code',
      label: 'Distributor Code'
    }, {
      field: 'name',
      label: 'Distributor Name'
    }];

  }
}
