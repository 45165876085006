import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationRequest } from '@app/core/interfaces/ttc/validation-request';
import { ValidationResponse } from '@app/core/interfaces/validation-response';
import { ActivityIndicatorService } from '@app/core/services/activity-indicator/activity-indicator.service';
import { TradeUtilityService } from '@app/core/services/ttc/trade-utility.service';

@Component({
  selector: 'app-validate-file',
  templateUrl: './validate-file.component.html',
  styleUrls: ['./validate-file.component.scss']
})
export class ValidateFileComponent {
  @Output() uploadFile = new EventEmitter();
  @Input() fileExtentions: string;
  @Input() validationData: string;
  @Input() validationType: string;
  response: ValidationResponse;

  constructor(private activityIndicatorService: ActivityIndicatorService,
    private tus: TradeUtilityService) {

  }
  uploadedFile(event: Event) {
    this.uploadFile.emit(event);
  }

  runFiles() {
    this.activityIndicatorService.show();

    return new Promise<boolean>((resolve, reject) => {
      try {
        const request: ValidationRequest = {
          headers: {
            'X-Fresh-Data': 'false',
            requestorType: 'TTCSpa',
            requestorId: "Derbysoft",
            propertyBrand: "WDW"
          },
          params: {
            validationType: this.validationType
          }
        };
        this.tus.validate(request).subscribe((validationResponse) => {
          this.response = validationResponse;
        });
      }
      catch (error) {
        reject(error);
        this.activityIndicatorService.hide();
      } finally {
        resolve(true);
        this.activityIndicatorService.hide();
      }
    });
  }
}
