import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MyIdService } from "@core/services/myid/myid.service";
import { WindowRefService } from '@services/window-ref/window-ref.service';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class MyIDGuard implements CanActivate {

  static STORAGE_KEY_AUTH_REDIRECT_ATTEMPTS = 'auth-redirect-attempts';

  constructor(
    private router: Router,
    private myIdService: MyIdService,
    private windowRef: WindowRefService,
    private locationService: Location
  ) {
    this.myIdService.logout$.subscribe(() => {
      this._clearRedirectionAttempts();
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const requiredAbilities = route.data?.abilities?.length > 0 ? (route.data.abilities) : ([]);

    const currentSession = this.myIdService.getCurrentSession().valid ? (this.myIdService.getCurrentSession()) : (this.myIdService.restoreMyIdSession());

    return Promise.resolve({
      isValid: currentSession.valid,
      hasAbilities: this.myIdService.hasAbilities(requiredAbilities, currentSession)
    }).then((authResult) => {

      // This is used primarily so that when we navigate to the unauthorized page that we preserve the original route
      const useRoutedUrlState = (guardResult): boolean => {
        setTimeout(() => {
          this.locationService.replaceState(state.url);
        });

        return guardResult;
      };

      // Is logged in, has the correct functional abilities to view page
      if (authResult.isValid && authResult.hasAbilities)  {

        this._clearRedirectionAttempts();

        return Promise.resolve(true);

      }

      // Is logged in, does not have the correct functiona abilities to view page
      if (authResult.isValid && !authResult.hasAbilities) {

        return this.router.navigate(['web/login/unauthorized']).then(useRoutedUrlState);

      }

      // If we reached here, we have to send them to MyId; however store the unsuccessful attempts so we don't keep sending them directly.
      if(this._incrementAndGetRedirectionAttempts() > 1) {

        return this.router.navigate(['web/login/unauthorized']).then(useRoutedUrlState);
      } else {

        return this.router.navigate(['web/login']).then(useRoutedUrlState);
      }

    });
  }

  private _clearRedirectionAttempts() {
    console.log('Clearing session authentication redirection attempts');
    const storage = this.windowRef.nativeWindow.localStorage;
    storage.removeItem(MyIDGuard.STORAGE_KEY_AUTH_REDIRECT_ATTEMPTS);
  }

  private _incrementAndGetRedirectionAttempts() {

    const storage = this.windowRef.nativeWindow.localStorage;
    const storedCountDatum = storage.getItem(MyIDGuard.STORAGE_KEY_AUTH_REDIRECT_ATTEMPTS);
    let updatedCount: { attempts: number };

    if (storedCountDatum) {
      updatedCount = JSON.parse(storedCountDatum);
      updatedCount.attempts += 1;
    } else {
      updatedCount = {
        attempts: 1
      };
    }

    storage.setItem(MyIDGuard.STORAGE_KEY_AUTH_REDIRECT_ATTEMPTS, JSON.stringify(updatedCount));

    return updatedCount.attempts;
  }

}
