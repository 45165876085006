import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ChannelManagerPageComponent } from '@pages/channel-manager-page/channel-manager-page.component';
import { ChannelManagersPageComponent } from '@pages/channel-managers-page/channel-managers-page.component';
import { CommunicationsPageComponent } from '@pages/communications-page/communications-page.component';
import { DistributorPageComponent } from '@pages/distributor-page/distributor-page.component';
import { DistributorsPageComponent } from '@pages/distributors-page/distributors-page.component';
import { ErrorPageComponent } from '@pages/error-page/error-page.component';
import { HomePageComponent } from '@pages/home-page/home-page.component';
import { NotFoundPageComponent } from '@pages/not-found-page/not-found-page.component';
import { OrphanTransactionsPageComponent } from '@pages/orphan-transactions-page/orphan-transactions-page.component';
import { ProductsPageComponent } from '@pages/products-page/products-page.component';
import { StopsellChannelManagerPageComponent } from '@pages/stopsell-channel-manager-page/stopsell-channel-manager-page.component';
import { StopsellDistributorPageComponent } from '@pages/stopsell-distributor-page/stopsell-distributor-page.component';
import { StopsellGlobalPageComponent } from '@pages/stopsell-global-page/stopsell-global-page.component';
import { StopsellLandingPageComponent } from '@pages/stopsell-landing-page/stopsell-landing-page.component';
import { StopsellPackageComponentPageComponent } from '@pages/stopsell-package-component-page/stopsell-package-component-page.component';
import { StopsellProductTypePageComponent } from '@pages/stopsell-product-type-page/stopsell-product-type-page.component';
import { StopsellRoomPackagePageComponent } from '@pages/stopsell-room-package-page/stopsell-room-package-page.component';
import { StopsellTicketsPageComponent } from '@pages/stopsell-tickets-page/stopsell-tickets-page.component';
import { SyncPackagesPageComponent } from '@pages/sync-packages-page/sync-packages-page.component';
import { UnauthorizedPageComponent } from '@pages/unauthorized-page/unauthorized-page.component';
import { UploadDistributorPageComponent } from '@pages/upload-distributor-page/upload-distributor-page.component';
import { ValidationDistributorsPageComponent } from '@pages/validation-distributors-page/validation-distributors-page.component';
import { ValidationPackagesPageComponent } from '@pages/validation-packages-page/validation-packages-page.component';
import { ValidationTicketsPageComponent } from '@pages/validation-tickets-page/validation-tickets-page.component';
import { ValidationsPageComponent } from '@pages/validations-page/validations-page.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';

/**
 * Modules act as IOC containers and we specify providers which
 * are effectively factories that help to inject objects into our components.
 */
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CoreModule,
    InputTextareaModule,
    FormsModule,
    PanelModule,
    AsyncPipe,
    NgForOf,
    NgIf,
    RouterOutlet,
    NgClass,
    RouterLink,
    RouterLinkActive
  ],
  providers: [
  ],
  /**
   * Declare every component that we are using which will be auto-wired when it's picked up in a template.
   */
  declarations: [
    ChannelManagerPageComponent,
    ChannelManagersPageComponent,
    CommunicationsPageComponent,
    DistributorPageComponent,
    DistributorsPageComponent,
    ErrorPageComponent,
    HomePageComponent,
    NotFoundPageComponent,
    OrphanTransactionsPageComponent,
    ProductsPageComponent,
    StopsellChannelManagerPageComponent,
    StopsellDistributorPageComponent,
    StopsellGlobalPageComponent,
    StopsellLandingPageComponent,
    StopsellPackageComponentPageComponent,
    StopsellProductTypePageComponent,
    StopsellRoomPackagePageComponent,
    StopsellTicketsPageComponent,
    SyncPackagesPageComponent,
    UnauthorizedPageComponent,
    UploadDistributorPageComponent,
    ValidationDistributorsPageComponent,
    ValidationPackagesPageComponent,
    ValidationTicketsPageComponent,
    ValidationsPageComponent
  ]
})
export class AppPagesModule { }
