
    <div class="channelManagerResync-main">

      <div class="title">
        <h2>{{title}}</h2>
      </div>

      <div class="header-wrapper">

        <div class="cm-wrapper">
          <wdpr-single-select
            #cmSelectInput
            label="Channel Manager"
            required-field="true"
            [attr.disabled]="channelManagerSelectOptions.length === 0 ? true : null"
            [attr.options]="JSON.stringify(channelManagerSelectOptions)"
            (item-selected)="onSelectChannelManager($event)"
          ></wdpr-single-select>
        </div>

        <div class="dest-wrapper">
          <wdpr-single-select
            #destinationSelectInput
            label="Destination"
            required-field="true"
            [attr.disabled]="destinationSelectOptions.length === 0 ? true : null"
            [attr.options]="JSON.stringify(destinationSelectOptions)"
            (item-selected)="onSelectDestination($event)"
          ></wdpr-single-select>
        </div>
        <div class="date-wrapper">
         
          <wdpr-datepicker  #startDateInput  variant="editable-input" editable-format-label="yyyy-MM-dd" format="yyyy-MM-dd" input-label="Start Date" text-input-placeholder="yyyy-MM-dd" editable-input-error-message='["Please enter a valid date"]' (selected-date)="saveSelecteStartDate($event.detail.selectedDate)"/>
        </div>
        <div class="date-wrapper">
          <wdpr-datepicker #endDateInput variant="editable-input" editable-format-label="yyyy-MM-dd" format="yyyy-MM-dd" input-label="End Date" text-input-placeholder="yyyy-MM-dd" editable-input-error-message='["Please enter a valid date"]' [minEnabledDate]="computeDateInTheFuture(1)" [maxEnabledDate]="computeDateInTheFuture(30)" (selected-date)="saveSelectedEndDate($event.detail.selectedDate)"/>
        </div>
        <div class="submit-stopsell-wrapper"> 
          <wdpr-button [disabled]="enableSync()" (click)="onSyncClicked()">Sync</wdpr-button></div>
      </div>
      <div class="grid-wrapper">
        <app-flat-data-table
          #dataTable
          [data]="datum"
          [config]="dataTableConfig"
        ></app-flat-data-table>
      </div>
      <wdpr-toast #failureToast dismissable message="Channel Manager Sync failed"/>
      <wdpr-toast #successToast success dismissable message="Channel Manager Sync successfull"/>

        
