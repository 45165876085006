<div slot="body">
  <div class="main-content">
    <h2> Manage Orphan Transactions </h2>
    <div class="alertButtons">
    <wdpr-button id="submitBtn" [attr.disabled]="dataTable.getSelectedData().length > 0 ? (undefined) : true" (click)="onSubmit()"> Clear Status </wdpr-button>

      <div class="grid-wrapper">
        <app-flat-data-table
          #dataTable
          [data]="datum"
          [config]="dataTableConfig"
        ></app-flat-data-table>
      </div>
</div>

</div>
</div>
