<wdpr-card routerLink="/web/stop-sell/global">
  <div slot="card-content">
    <div class="title row">
      Global
    </div>
    <div class="row">
      <p>
        Toggle all distributors for the given channel-manager + destination onto a global stop-sell.
      </p>
    </div>
  </div>
</wdpr-card>
<wdpr-card routerLink="/web/stop-sell/product-type">
  <div slot="card-content">
    <div class="title row">
      Product Type
    </div>
    <div class="row">
      <p>
        Toggle a product type stop-sell for distributors for a given channel manager and destination.
      </p>
    </div>
  </div>
</wdpr-card>
<wdpr-card routerLink="/web/stop-sell/package-code">
  <div slot="card-content">
    <div class="title row">
      Package Code
    </div>
    <div class="row">
      <p>
        Toggle a package stop-sell
      </p>
    </div>
  </div>
</wdpr-card>
<!--<wdpr-card routerLink="/web/stop-sell/package-component">-->
<!--  <div slot="card-content">-->
<!--    <div class="title row">-->
<!--      Package Component-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <p>-->
<!--        Sets the distributors on stopsell for given package component (pkgTicket/mealPlan).-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--</wdpr-card>-->
<wdpr-card routerLink="/web/stop-sell/ticket-code">
  <div slot="card-content">
    <div class="title row">
      Ticket Code
    </div>
    <div class="row">
      <p>
        Sets the distributors on stopsell for given ticket codes.
      </p>
    </div>
  </div>
</wdpr-card>

<wdpr-card routerLink="/web/stop-sell/channel-manager">
  <div slot="card-content">
    <div class="title row">
      Channel Manager
    </div>
    <div class="row">
      <p>
        Sets the distributors on manual stopsell for the given Channel Manager.
      </p>
    </div>

  </div>
</wdpr-card>
<wdpr-card routerLink="/web/stop-sell/distributor">
  <div slot="card-content">
    <div class="title row">
      Distributor
    </div>
    <div class="row">
      <p>
        Sets the distributors stopsell.
      </p>
    </div>

  </div>
</wdpr-card>
