import { Component, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DataTableColumn } from "@interfaces/data-table-column";
import { OrphanTransaction } from "@interfaces/orphan-transaction";
import { TradeUtilityService } from '@services/ttc/trade-utility.service';
import { FlatDataTable } from "@interfaces/flat-data-table";
import { DataTableRow } from "@interfaces/data-table-row";
import { LoggerService } from '@wdpr/ra-angular-logger';
import { FlatDataTableComponent } from "@core/components/flat-data-table/flat-data-table.component";
import { ActivityIndicatorService } from "@services/activity-indicator/activity-indicator.service";

@Component({
  selector: 'app-orphan-transactions-page',
  templateUrl: './orphan-transactions-page.component.html',
  styleUrls: ['./orphan-transactions-page.component.scss']
})
export class OrphanTransactionsPageComponent implements OnInit {
  @ViewChild('dataTable') dataTable: FlatDataTableComponent;
  dataTableConfig: FlatDataTable;
  datum: Promise<DataTableRow[]>;
  rawTableDatum: any[] = [];
  orphanTransactions: OrphanTransaction[];

  private populateDataTable() {
    this.datum = Promise.resolve(this.rawTableDatum);
  }
  columns: DataTableColumn[];

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService,
    private logger: LoggerService
  ) {

  }

  ngOnInit(): void {
    this.initializeDataTable();
    this.activityService.show("Loading Orphan Transactions...");
    this.getOrphanTransactions();
  }

  private initializeDataTable() {
    this.dataTableConfig = {
      columns: [
        {
          field: 'timeStamp',
          label: 'Timestamp',
          sortable: true
        }, {
          field: 'payloadId',
          label: 'Payload Id'
        }, {
          field: 'status',
          label: 'Status',
          sortable: true
        }, {
          field: 'tsmac',
          label: 'tsmac',
          sortable: true
        }, {
          field: 'tsloc',
          label: 'tsloc',
          sortable: true
        }, {
          field: 'initiatorOrderIdValue',
          label: 'Initiator OrderId'
        }, {
          field: 'distributorCode',
          label: 'Distributor Code',
          sortable: true
        }, {
          field: 'errorCode',
          label: 'Error Code',
          sortable: true
        }, {
          field: 'errorMessage',
          label: 'Error Message'
        },{
          field: 'autoCleanUpAttemptTimeStamp',
          label: 'Cleanup Timestamp',
          sortable: true
        }, {
          field: 'autoCleanUpAttemptErrorCode',
          label: 'Cleanup Error Code',
          sortable: true
        }, {
          field: 'autoCleanUpAttemptErrorMessage',
          label: 'Cleanup Error Message'
        }
      ],
      displayGrid: false,
      enableEditableRows: false,
      enableLazyScroll: true,
      enableQuickFilter: true,
      enableSelection: true,
      lockManualSelection: false,
      primaryKey: 'rowId',
      size: 'sm'
    };
  }

  private getOrphanTransactions() {
    firstValueFrom( this.tus.getOrphanTransactions())
      .then(response => {
        this.orphanTransactions = response;
        this.rawTableDatum = response.map((orphan, index) => {

          const rowItem: DataTableRow = {
            rowId: index,
            timeStamp: orphan.timeStamp,
            payloadId: orphan.payloadId,
            status: orphan.status,
            tsmac: orphan.tsmac,
            tsloc: orphan.tsloc,
            initiatorOrderIdValue: orphan.initiatorOrderIdValue,
            distributorCode: orphan.distributorCode,
            errorCode: orphan.errorCode,
            errorMessage: orphan.errorMessage,
            autoCleanUpAttemptTimeStamp: orphan.autoCleanUpAttemptTimeStamp,
            autoCleanUpAttemptErrorCode: orphan.autoCleanUpAttemptErrorCode,
            autoCleanUpAttemptErrorMessage: orphan.autoCleanUpAttemptErrorMessage
          };

          return rowItem;

        });
      }).catch((reason) => {
      this.logger.error('', { contextMsg: 'Failed to retrieve orphan transactions', reason });
    }).finally(() => {
      this.populateDataTable();
      this.activityService.hide();
    });
  }
  onSubmit() {
    this.activityService.show("Updating Orphan Transactions...");
    const ids = this.dataTable.getSelectedData().map(value => value.payloadId);
    const updateOrphanTransactions = this.orphanTransactions.filter(value => ids.includes(value.payloadId)).map(value => {
      value.status = "";

      return value;
    });

    firstValueFrom(this.tus.updateOrphanTransactions(updateOrphanTransactions)).catch((reason) => {
      this.logger.error('', { contextMsg: 'Failed to update orphan transactions', reason });
    }).finally(() => {
      this.activityService.hide();
      this.getOrphanTransactions();
    });

  }
}
