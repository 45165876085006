import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { WindowRefService } from '@services/window-ref/window-ref.service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigService } from "@services/config/config.service";
import { Router } from '@angular/router';
import { OIDCToken } from '@interfaces/oidc-token';
import { OIDCSession, OIDC_EMPTY_SESSION } from '@interfaces/oidc-session';
import { OIDCUserDetails } from '@interfaces/oidc-user-details';
import { OIDCRole } from '@interfaces/oidc-role';

@Injectable({
  providedIn: 'root'
})
export class MyIdService {

  // Used to notify consumers when the application identity changes
  private _oidcSessionSubject = new BehaviorSubject<OIDCSession>(OIDC_EMPTY_SESSION);
  // Used to notify consumers when the user is performing a logout
  private _oidcLogoutSession = new Subject<OIDCSession>();

  public token$: Observable<OIDCSession> = this._oidcSessionSubject.asObservable();
  public logout$: Observable<OIDCSession> = this._oidcLogoutSession.asObservable();

  constructor(
    private router: Router,
    private config: ConfigService,
    private location: Location,
    private logger: LoggerService,
    private windowRef: WindowRefService
  ) {
  }

  /**
   * Checks the OIDC nonce to determine if the token originated from the client or not.
   * @private
   * @param session
   */
  private _checkNonce(session: OIDCSession) {

    // Check to see if nonce guard matches, this prevents tokens from being relayed
    const nonceRoute = this.getSavedRoute(session.oidcToken.nonce);
    if (nonceRoute) {

      // TODO: Refactor the combined guard + OIDC redirector + this to support the redirector to handle nonce routing
      this.router.navigate([nonceRoute]);

      return session;

    } else {

      return {
        valid: false,
        oidcToken: undefined
      };

    }
  }

  /**
   * Processes the callback path back into the SPA from MyID to login page.
   * @private
   */
  private _handleMyIdCallback(pathWithHash: string): OIDCSession {

    console.log('Handling callback from MyID');
    try {
      const hashString = pathWithHash.split('#')[1];
      const routeParams = new URLSearchParams(hashString);
      // Retrieve MyID token details from URL path
      const idToken = routeParams.get('id_token');
      const accessToken = routeParams.get('access_token');

      const parsedToken = this._parseJwt(accessToken, idToken);

      // Check the nonce token from MyID and ensure it's something that came from us
      if (parsedToken.accessToken && parsedToken.idToken) {

        const storage = this.windowRef.nativeWindow.localStorage;

        const session: OIDCSession = {
          oidcToken: parsedToken, valid: true
        };

        storage.setItem('oidc-session', JSON.stringify(session));

        return session;

      } else {

        return {
          valid: false,
          oidcToken: undefined
        };

      }

    } catch (error) {

      const wrappedError = {
        contextMsg: 'Error occurred handling MyID callback',
        error
      };
      this.logger.log('{}', wrappedError);

      return OIDC_EMPTY_SESSION;

    }

  }

  /**
   * Parses the MyID JWT token using a combination of base64 decoding and some regex to split between some magic constants.
   * @param accessToken
   * @param idToken idToken from MyID callback.
   * @private
   * @return OIDCToken parsed token.
   */
  private _parseJwt (accessToken: string, idToken: string): OIDCToken {

    // Decode JWT token
    const base64Url = idToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const decodedToken = JSON.parse(jsonPayload);
    console.log('Decoded OIDC Token:', decodedToken);

    // Retrieve details we need for a valid session
    const userDetails: OIDCUserDetails = {
      identity: decodedToken.sub,
      email: decodedToken.email,
      familyName: decodedToken.family_name,
      fullName: decodedToken.name,
      givenName: decodedToken.given_name,
      nickname: decodedToken.nickname,
      profile: decodedToken.profile
    };

    let userRoles: OIDCRole[] = [];
    // If MyID isn't configured for full-assertion, roles will come down as an array but lacking their attributes
    if (decodedToken.roles && !Array.isArray(decodedToken.roles)) {
      const decodedRoles = JSON.parse(decodedToken.roles);
      userRoles = decodedRoles.map(role => ({
        abilities: role.functionalAbilities, attributes: role.attributes, name: role.name
      }));
    }

    return {
      // Note: MyID session length is determined by the Keystone cache length, MyID is not intended to be utilized as session storage
      expiresAt: decodedToken.exp * 1000, issuedAt: decodedToken.iat * 1000, // To support JS date conversion multi by 1000
      roles: userRoles,
      accessToken: accessToken,
      idToken: idToken,
      userDetails,
      nonce: decodedToken.nonce
    };

  }

  /**
   * Executes login workflow, sending user to MyID.
   */
  public redirectToMyID(returnRoute): void {

    console.log('Performing login with redirect to ', returnRoute);

    let loginUrl = this.config.getValue('WEB_MYID_LOGIN_URL');

    const nonce = `TTC-Admin-UI-${Date.now()}`;
    loginUrl = loginUrl.replace('APPLICATION_GENERATED_ONE_TIME_NONCE', nonce);

    // Store the route to retrieve later, we need to verify that our nonce we have set is what we redirect back with to
    // guard against relay attacks.
    this.saveRoute(nonce, returnRoute);

    // Send user to MyID
    this.windowRef.nativeWindow.open(loginUrl, '_self');

  }

  /**
   * @desc Will attempt to either process a login callback from MyID or
   * try to automatically restore the persisted (if it exists) MyID session.
   * @return {OIDCSession} Current applicable session
   */
  public processLoginOrRestoreSession(): OIDCSession {

    // Check the path we are on, if it looks like the MyID path extract details from the callback
    const pathWithHash = this.location.path(true);
    const isOnLoginRoute = pathWithHash.includes('access_token=') &&
      pathWithHash.includes('id_token=') &&
      pathWithHash.includes('token_type=Bearer');

    // If we are on the login route extract our MyID information
    let session: OIDCSession;
    if (isOnLoginRoute) {

      session = this._handleMyIdCallback(pathWithHash);

      // Check OIDC Nonce
      if(session.valid) {
        session = this._checkNonce(session);
      }

    } else {

      session = this.restoreMyIdSession();

    }

    console.log('OIDC Session:', session);
    this._oidcSessionSubject.next(session);

    return session;

  }

  /**
   * Retrieves the current active session.
   */
  public getCurrentSession(): OIDCSession {
    return this._oidcSessionSubject.getValue();
  }

  /**
   * Attempts to restore the MyID OIDC session; in the event it's no longer valid it will be cleared.
   * @private
   */
  public restoreMyIdSession(broadcast = false): OIDCSession {

    console.log('Attempting to restore MyID session from browser storage.');
    const storage = this.windowRef.nativeWindow.localStorage;
    const storedOidcSession = storage.getItem('oidc-session');

    if (!storedOidcSession) {
      return this._oidcSessionSubject.getValue();
    }

    const session: OIDCSession = JSON.parse(storedOidcSession);

    if (session.oidcToken.accessToken && session.oidcToken.idToken) {
      session.valid = true;
    } else {
      storage.removeItem('oidc-session');
      session.valid = false;
    }

    if (broadcast) {
      this._oidcSessionSubject.next(session);
    }

    return session;

  }

  /**
   * Verifies that the user has the given functional abilities.
   * @param desiredAbilities
   * @param session
   */
  hasAbilities(desiredAbilities: [], session = undefined): boolean {

    if(!session) {
      session = this._oidcSessionSubject.getValue();
    }

    const sessionAbilities = session.oidcToken.roles.map((role) => {
      return role.abilities.map(ability => {return ability['name'];});
    }).flatMap((roleAbilities) => { return roleAbilities; });

    return desiredAbilities.every(ability => sessionAbilities.includes(ability));

  }

  /**
   * @summary Unsets the MyID session from the app & redirects user to the MyID logout page.
   * @desc In order for the user to be fully logged out they'll have to be sent off to MyID
   * to clear their session.
   */
  logout(skipRedirect = false): void {

    this._oidcLogoutSession.next(this._oidcSessionSubject.getValue());

    const storage = this.windowRef.nativeWindow.localStorage;
    storage.removeItem('oidc-session');
    storage.removeItem('auth-redirect-attempts');

    this._oidcSessionSubject.next(OIDC_EMPTY_SESSION);

    if(!skipRedirect) {
      this.router.navigate(['/web/home']);
    }

  }

  /**
   * @summary Saves the supplied path in Local Storage for later retrieval.
   * @desc OIDC allows you to pass a state nonce. Store the user’s state
   * in local storage with a nonce as the key, pass that nonce to
   * MyID in the state parameter, we will pass it back after
   * authentication. You use a localStorage/nonce for CSRF protection
   * since the state data will only exist by nonce in the requestors browser.
   * @param nonce An application-specific value specific to a single login.
   * @param path The path to be saved for a specific login.
   * @returns void
   */
  saveRoute(nonce, path): void {

    const storage = this.windowRef.nativeWindow.localStorage;
    storage.setItem(nonce, path);

  }

  /**
   * @desc Retrieves the saved path after the login.
   * @param nonce An application-specific value specific to a single login.
   * @returns The saved path, which the user was trying to visit before login.
   */
  getSavedRoute(nonce): string {

    const storage = this.windowRef.nativeWindow.localStorage;

    const path = storage.getItem(nonce);
    storage.removeItem(nonce);

    return path;

  }
}
