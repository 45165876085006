<div class="validations-main">
  <app-validate-file id="ticketListsFileUpload" (uploadFile)="uploaddistributorsValidationFiles($event)"
  [fileExtentions]="fileExtentions" [validationData]="data" [validationType]="validationType"/>
  <div class="tabs-wrapper">
    <wdpr-tabs #validationTabs selected-tab-index="0" (tab-changed)="onTabChanged()">
      <wdpr-tab id="tab-product-codes">
        Distributors
      </wdpr-tab>
      <div slot="body">
        <div data-tab-index="0" tabindex="0" labelledby="tab-product-codes" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table/>
          </div>
        </div>
      </div>
    </wdpr-tabs>
  </div>
</div>
