<div class="container">
<div class="comp1"><app-file-upload-input class="width" id="fileUpload" (uploadFile)="uploadFiles($event)" [fileExtentions]="fileExtentions"/></div>
<div class="comp2"><wdpr-button class="button"  #runAllBtn (click)="runFiles()" theme="secondary" [disabled]="data===undefined">
  <i class="di di-media-play-button"></i>Run All
</wdpr-button>
</div>
</div>
  <app-pagination-data-table #table
    [columns]="headers"
    [rowData]="uploadDistributorGridRows"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[10, 25, 50]"
  ></app-pagination-data-table>




