import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@services/config/config.service';
import { DateTimeSpanWindows, RatePlanCode, SyncRoomAvailRequest } from '@app/core/interfaces/ttc/sync-room-avail-request';

@Injectable({
  providedIn: 'root'
})
export class TradeInventoryService {

  private configs: object;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
    this.configs = this.configService.get();
  }

  public syncChannelManager(
    ratePlanCodes: RatePlanCode[],
    channelManager: string,
    destination: string,
    dateTimeSpanWindows : DateTimeSpanWindows[]
  ) {

    const options = {
      headers: {
        requestorid: channelManager,
        propertybrand: destination
      },
      params: {}
    };

    const bodyRequest: SyncRoomAvailRequest = {
      ratePlanCodes,
      dateTimeSpanWindows 
    };

    return this.httpClient.post<[]>(
      `${this.configs['WEB_API_BASE_URL']}/admin/sync-room-avail`,
      bodyRequest,
      options
    );

  }

}
