import { Component, ViewChild } from '@angular/core';
import { DataTableColumn } from '@interfaces/data-table-column';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';

@Component({
  selector: 'app-upload-distributor-page',
  templateUrl: './upload-distributor-page.component.html',
  styleUrls: ['./upload-distributor-page.component.scss']
})
export class UploadDistributorPageComponent {

  file: File = null;
  data: object[];
  headers: DataTableColumn[];
  uploadDistributorGridRows: object[];

  fileExtentions = ".xls, .csv, .xlsx";
  @ViewChild('table') _dataTable: FlatDataTableComponent;

constructor(
  private activityIndicatorService: ActivityIndicatorService ){

}
  uploadFiles(event) {
    const file: File = event;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: WorkBook = read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.data = utils.sheet_to_json(ws);
      // populates data needed for data table.
      this.headers = [];
      this.uploadDistributorGridRows = [];
      Object.keys(this.data[0] || {}).forEach(key => {
        this.headers.push({
          field: key, label: key
        });
      });
      Object.values(this.data || {}).forEach((value) => {
        this.uploadDistributorGridRows.push(value);
      });
    };}

    runFiles() {
      this.activityIndicatorService.show();
      setTimeout(() => {
        this.activityIndicatorService.hide();
      }, 4000);
    }
  }




