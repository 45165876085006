import { Component, ElementRef, ViewChild } from '@angular/core';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { DataTableColumn } from '@interfaces/data-table-column';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';

@Component({
  selector: 'app-validation-packages-page',
  templateUrl: './validation-packages-page.component.html',
  styleUrls: ['./validation-packages-page.component.scss']
})
export class ValidationPackagesPageComponent {

  @ViewChild('validationTabs') _tabs: ElementRef;

  @ViewChild('packageTable') _packageTable: FlatDataTableComponent;

  // Package Details Validations
  packageDetailsIssues: string | number = 'N/A';
  packageDetailsColumns: DataTableColumn[];
  packageDetailsRows: object[];
  packageData: object[];

  // Package Price Validations
  packagePriceIssues: string | number = 'N/A';
  packagePriceColumns: DataTableColumn[];
  packagePriceRows: object[];

  // Source Market Validations
  sourceMarketIssues: string | number = 'N/A';
  sourceMarketColumns: DataTableColumn[];
  sourceMarketRows: object[];

  fileExtentions = ".xls, .csv, .xlsx";
  validationType: string;

  onTabChanged(event) {
    if (event.detail.selectedTabIndex == 0) {
      this.validationType = "room-package";
    } else if (event.detail.selectedTabIndex == 1) {
      this.validationType = "room-package-price";
    } else {
      this.validationType = "room-packages";
    }
  }

  getStatusType(value: any) {

    if (isNaN(value)) {
      return 'inactive';
    }

    if (!isNaN(value)) {
      if (value > 0) {
        return 'alert';
      } else {
        return 'success';
      }
    }

  }

  uploadPackageValidationFiles(event) {

    const file: File = event as File;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);

    return new Promise<boolean>((resolve, reject) => {

      reader.onload = (e: any) => {
        try {
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: WorkBook = read(binarystr, { type: 'binary' });
          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: WorkSheet = wb.Sheets[wsname];
          /* save data */
          this.packageData = utils.sheet_to_json(ws);
          // populates data needed for data table.
          this.packageDetailsColumns = [];
          this.packagePriceColumns = [];
          this.sourceMarketColumns = [];
          this.packageDetailsRows = [];
          this.packagePriceRows = [];
          this.sourceMarketRows = [];
          Object.keys(this.packageData[0] || {}).forEach(key => {
            if (this.validationType.includes('room-package-price')) {
              this.packagePriceColumns.push({
                field: key, label: key
              });
            } else if (this.validationType.includes('room-packages')) {
              this.sourceMarketColumns.push({
                field: key, label: key
              });
            } else {
              this.packageDetailsColumns.push({
                field: key, label: key
              });
            }
          });
          Object.values(this.packageData || {}).forEach((value) => {
            if (this.validationType.includes('room-package-price')) {
              this.packagePriceRows.push(value);
            } else if (this.validationType.includes('room-packages')) {
              this.sourceMarketRows.push(value);
            } else {
              this.packageDetailsRows.push(value);
            }
          });
        } catch (error) {
          reject(error);
        } finally {
          resolve(true);
        }
      };
    });

  }

}
