
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataTableColumn } from '@interfaces/data-table-column';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';

@Component({
  selector: 'app-validation-tickets-page',
  templateUrl: './validation-tickets-page.component.html',
  styleUrls: ['./validation-tickets-page.component.scss']
})
export class ValidationTicketsPageComponent {

  @ViewChild('validationTabs') _tabs: ElementRef;

  @ViewChild('ticketsTable') _ticketsDataTable: FlatDataTableComponent;

  // Product Code Validations
  productsIssues: string | number = 'N/A';
  ticketsColumns: DataTableColumn[];
  ticketDetailsColumns: DataTableColumn[];
  ticketPriceColumns: DataTableColumn[];
  ticketsGridRows: object[];
  ticketsData: object[];
  ticketDetailsGridRows: object[];
  ticketPriceGridRows: object[];

  // Product Details Validations
  productCodeIssues: string | number = 'N/A';

  // Prices Validations
  pricesIssues: string | number = 'N/A';
  fileExtentions = ".xls, .csv, .xlsx";
  validationType: string;


  getStatusType(value: any) {

    if (isNaN(value)) {
      return 'inactive';
    }

    if (!isNaN(value)) {
      if (value > 0) {
        return 'alert';
      } else {
        return 'success';
      }
    }

  }

  onTabChanged(event) {
    if (event.detail.selectedTabIndex == 0) {
      this.validationType = "ticket-codes";
    } else if (event.detail.selectedTabIndex == 1) {
      this.validationType = "ticket-details";
    } else {
      this.validationType = "ticket-price";
    }
  }

  uploadTicketValidationFiles(event) {

    const file: File = event as File;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);

    this.ticketsColumns = [];
    this.ticketDetailsColumns = [];
    this.ticketPriceColumns = [];
    this.ticketsGridRows = [];
    this.ticketDetailsGridRows = [];
    this.ticketPriceGridRows = [];

    return new Promise<boolean>((resolve, reject) => {

      reader.onload = (e: any) => {
        try {

          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: WorkBook = read(binarystr, { type: 'binary' });
          /* selected the first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: WorkSheet = wb.Sheets[wsname];
          /* save data */
          this.ticketsData = utils.sheet_to_json(ws);

          // populates data needed for data table.
          Object.keys(this.ticketsData[0] || {}).forEach(key => {
            if (this.validationType.includes('ticket-codes')) {
              this.ticketsColumns.push({
                field: key, label: key
              });
            } else if (this.validationType.includes('ticket-details')) {
              this.ticketDetailsColumns.push({
                field: key, label: key
              });
            } else {
              this.ticketPriceColumns.push({
                field: key, label: key
              });
            }
          });

          Object.values(this.ticketsData || {}).forEach((value) => {
            if (this.validationType.includes('ticket-codes')) {
              this.ticketsGridRows.push(value);
            } else if (this.validationType.includes('ticket-details')) {
              this.ticketDetailsGridRows.push(value);
            } else {
              this.ticketPriceGridRows.push(value);
            }
          });

        } catch (error) {
          reject(error);
        } finally {
          resolve(true);
        }

      };
    });

  }
}


