<wdpr-tabs>
  <wdpr-tab id="tab-b0" role="tab" aria-controls="tabpanel-b0">Stopsell</wdpr-tab>
  <wdpr-tab id="tab-b1" role="tab" aria-controls="tabpanel-b1">History</wdpr-tab>

  <div slot="body" id="tabbed-outlet">

    <div class="tab-content stopsell-main" data-tab-index="0" tabindex="0" id="tabpanel-b0" role="tabpanel" aria-labelledby="tab-b0">

      <div class="title">
        <h2>{{title}}</h2>
      </div>

      <div class="header-wrapper">

        <div class="cm-wrapper">
          <wdpr-single-select
            #cmSelect
            [attr.options]="JSON.stringify(channelManagerSelectOptions)"
            (item-selected)="onSelectChannelManager($event)"
            label="Channel Manager"
          >
          </wdpr-single-select>
        </div>

        <div class="dest-wrapper">
          <wdpr-single-select
            #destSelect
            [attr.disabled]="destinationSelectOptions.length === 0 ? true : null"
            [attr.options]="JSON.stringify(destinationSelectOptions)"
            (item-selected)="onSelectDestination($event)"
            label="Destination"
          ></wdpr-single-select>
        </div>

        <div class="submit-stopsell-wrapper">
          <wdpr-button
            #stopSellToggleBtn
            [attr.disabled]="remarksEntered.length === 0 ? true : null"
            id="submitBtn"
            (click)="onStopsellToggleClicked()"
          >
            Toggle Stopsell
          </wdpr-button>
        </div>

      </div>

      <div class="remark-wrapper">
          <span class="p-float-label">
            <textarea #remarksInput id="remarks-input"
                      rows="4" cols="55"
                      pInputTextarea
                      [disabled]="destSelected.length === 0"
                      [(ngModel)]="remarksEntered"
            >
            </textarea>
            <label for="remarks-input">Remarks</label>
          </span>
      </div>

      <div class="grid-wrapper">
        <app-flat-data-table
          #dataTable
          [data]="datum"
          [config]="dataTableConfig"
          (dataLoaded)="onDataTableLoaded()"
        ></app-flat-data-table>
      </div>

      <app-stopsell-pocket-panel
        #stopSellPocketPanel
        [title]="pocketPanelTitle"
        (saveStopSell)="onToggleStopsell()"
        [pocketPanelBody]="pocketPanelBodyStopsell"
      >
      </app-stopsell-pocket-panel>

    </div>

    <div class="tab-content history-main" data-tab-index="1" tabindex="-1" id="tabpanel-b1" role="tabpanel" aria-labelledby="tab-b1">
      <div class="history-wrapper">
        <div class="title">
          <h2>History</h2>
        </div>
        <div slot="body">
          <app-stopsell-history #stopsellHistory [type]="type"/>
        </div>
      </div>
    </div>

  </div>
</wdpr-tabs>
