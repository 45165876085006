import { Component } from '@angular/core';

@Component({
  selector: 'app-stopsell-landing-page',
  templateUrl: './stopsell-landing-page.component.html',
  styleUrls: ['./stopsell-landing-page.component.scss']
})
export class StopsellLandingPageComponent {

}
