import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataTableColumn } from "@interfaces/data-table-column";
import { TicketCodeStopsellGridRow } from "@interfaces/ticket-code-stopsell-grid-row";
import { StopsellPocketPanelComponent } from '@core/components/stopsell-pocket-panel/stopsell-pocket-panel.component';
import { StopSellType } from "@services/ttc/stop-sell-type";
import { TradeUtilityService } from "@services/ttc/trade-utility.service";
import { TradeProductService } from "@services/ttc/trade-product.service";
import { Product } from "@interfaces/product";
import { catchError } from "rxjs";
import { ProductType } from "@services/ttc/product-type";
import { LoggerService } from "@wdpr/ra-angular-logger";
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { StopSellSelection } from '@interfaces/stop-sell-selection';

@Component({
  selector: 'app-stopsell-tickets',
  templateUrl: './stopsell-tickets-page.component.html',
  styleUrls: ['./stopsell-tickets-page.component.scss']
})
export class StopsellTicketsPageComponent implements OnInit {

  @ViewChild('stopSellOnConfirm') stopSellOnModal: ElementRef;

  columns: DataTableColumn[];
  ticketCodeStopsellGridRows: TicketCodeStopsellGridRow[];
  cmData: string[] = [];
  destData: string[] = [];
  ticketCodes: string[]=[];
  brandSelected: string;
  ticketCodesSelected: string[];
  channelManagerSelected: string;
  disableBtn = true;
  remarksEntered: string;
  displayStopsellOnNotification = false;
  title = "Ticket Stopsell";
  pocketPanelBodyStopsellON: string[] = [];
  distributorStopsellCount: number;
  destination: string;
  channelManager: string;
  ticketCodesOnStopsell:string[];
  ticketType: string[] = [];
  chanelManagers;
  ticketTypeSelected: string;
  ticketList: Product[] = [];
  ticketMap: Map<string, number> = new Map();
  stopsellresp;
  type : string = StopSellType.STANDALONE_TICKET_CODE;

  @ViewChild('PocketPanelComponent') stopsellPocketPanelComponent: StopsellPocketPanelComponent;

  constructor(
    private tus: TradeUtilityService, private tps: TradeProductService, private logger: LoggerService,
    private activityService: ActivityIndicatorService
  ) {
  }

  ngOnInit(): void {
    this.activityService.show("Retrieving Channel Managers...");
    this.tus.getChannelManagers()
      .subscribe((cmResponse) => {
        this.cmData = cmResponse.map(item => {
          return item.name;
        });
        this.chanelManagers = cmResponse.map(item => {
          return item;
        });
        this.activityService.hide();
      });
    this.ticketType = [StopSellType.STANDALONE_TICKET_CODE, StopSellType.PACKAGE_TICKET_CODE];

    this._initDataTableData();
  }

  onSelectBrand(event) {
      this.brandSelected = event.detail.value;
      this.enableSubmitButton();
  }

  onSelectTicketType(event) {
    this.ticketTypeSelected = event.detail.value;

    this.populateProducts();
    this.enableSubmitButton();
  }

  onSelectCM(event) {
      this.channelManagerSelected = event.detail.value;
      this.populateDropDowns();
      this.enableSubmitButton();
  }

  populateDropDowns() {
    for (let i = 0; i < this.chanelManagers.length; i++) {
      if (this.chanelManagers[i].name === this.channelManagerSelected) {
        this.destData = this.chanelManagers[i].propertyBrands.map(item => item.code);
        break;
      }
    }
  }

  onSelectTC(event) {
      this.ticketCodesSelected = [];
      if (event && event.detail && event.detail.value.length > 0) {
        this.ticketCodesSelected = event.detail.value.map(v => v.value);
      }
      this.enableSubmitButton();
  }

  onRemarksInput(event) {
      this.remarksEntered = event.target.value;
      this.enableSubmitButton();
  }

  enableSubmitButton() {
    if (this.brandSelected && this.channelManagerSelected && this.remarksEntered
            && this.ticketCodesSelected.length > 0) {
      this.disableBtn = false;
      this.pocketPanelBodyStopsellON = ["No of distributors being put on stopsell : " + this.ticketCodeStopsellGridRows.length,
      "Channel Manager : " + this.channelManagerSelected,
      "Destination : " + this.brandSelected,
      "Ticket Code : " + this.ticketCodesSelected,
        "Are you sure you want to put selected distributors on stopsell?"];
    } else {
      this.disableBtn = true;
    }
  }

  showConfirmationModal(){
    this.performStopSell();
    this.destination = this.brandSelected;
    this.channelManager = this.channelManagerSelected;
    this.ticketCodesOnStopsell = this.ticketCodesSelected;
    this.stopSellOnModal.nativeElement.open = true;
  }

  submitBtnOnClick() {
    this.stopsellPocketPanelComponent.openRightPanel();
  }

  cancelBtnOnClick() {
    this.stopSellOnModal.nativeElement.open = false;
  }

  _initDataTableData() {
    this.columns = [{
      field: 'ticketCode',
      label: 'Ticket Code'
    },
      {
      field: 'destination',
      label: 'Destination'
    }];
  }

  populateProducts() {
    this.tps.getProducts(this.channelManagerSelected, this.brandSelected, ProductType.TICKET)
      .subscribe((ticketsResponse) => {

        this.ticketList = ticketsResponse.map((ticket) => {
          return ticket;
        });

        this.ticketCodes = ticketsResponse.map(ticket => {
          return ticket.admissionProduct.ticketCode.code;
        });

        this.ticketCodeStopsellGridRows = ticketsResponse.map(product => {
          return {
            ticketCode: product.admissionProduct.ticketCode.code,
            destination: this.destination
          };
        });
      });
  }

  performStopSell() {
    this.productArrayToMap();

    this.tus.updateStopSell( this.mapToTicketStopSellSelections(),
      this.channelManagerSelected,
      this.brandSelected,
      this.ticketTypeSelected,
      true,
      this.remarksEntered,
      this.ticketCodesSelected
    )
      .pipe(
        catchError(error => {
          console.log('Error: ', error);

          return [];
        })
      )
      .subscribe((cmResponse) => {
        this.stopsellresp = cmResponse.map(item => {

          return item;
        });
      });

  }

  // Map ticket code to position on array
  productArrayToMap() {
    let i = 0;
    this.ticketCodes.map( ticketCode => {
      this.ticketMap.set(ticketCode, i);
      i++;
    });
  }

  mapToTicketStopSellSelections() {

    const stopsellSelections: StopSellSelection[]  = [];

    this.ticketCodesSelected.map( item => {
      stopsellSelections.push({
        productCode: item,
        distributors: this.ticketList[this.ticketMap.get(item)].distributors
      });
    });

    return stopsellSelections;
  }
}
