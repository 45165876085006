import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-panel-nav',
  templateUrl: './panel-nav.component.html',
  styleUrls: ['./panel-nav.component.scss']
})
export class PanelNavComponent implements OnInit {

  navigation = [];

  @Output() navigated = new EventEmitter();

  ngOnInit(): void {

    this.navigation = [
      {
        children: [
          { label: 'Home', url: '/web/home' }
        ]
      },
      {
        title: 'Validate', url: '/web/validations', children: [
          { label: 'Distributors', url: '/web/validations/distributors' },
          { label: 'Tickets', url: '/web/validations/tickets' },
          { label: 'Packages', url: '/web/validations/packages' }
        ]
      },
      {
        title: 'Manage', children: [
          { label: 'Upload Distributors', url: '/web/upload/distributors' },
          { label: 'Orphan Transactions', url: '/web/manage/orphan-transactions' },
          { label: 'Communications', url: '/web/manage/communications' }
        ]
      },
      {
        title: 'Sync', children: [
          { label: 'Packages', url: '/web/sync/packages' },
        ]
      },
      {
        title: 'Stopsell', url: '/web/stop-sell', children: [
          { label: 'Global', url: '/web/stop-sell/global' },
          { label: 'Product Type', url: '/web/stop-sell/product-type' },
          { label: 'Package Code', url: '/web/stop-sell/package-code' },
          { label: 'Ticket Code', url: '/web/stop-sell/ticket-code' },
          { label: 'Channel Manager', url: '/web/stop-sell/channel-manager' },
          { label: 'Distributor', url: '/web/stop-sell/distributor' },
          //{ label: 'Package Component', url: '/web/stop-sell/package-component' }
        ]
      },
      {
        title: 'View', children: [
          { label: 'Channel Managers', url: '/web/channel-managers' },
          { label: 'Distributors', url: '/web/distributors' },
          { label: 'Products', url: '/web/products' }
        ]
      }
    ];

  }

  onLinkClick() {
    this.navigated.emit();
  }

}
