import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss']
})
export class FileUploadInputComponent {

  errorMessage: string[];

  @ViewChild('fileUploadInfoSelect') _fileUploadInfoSelect: ElementRef;
  private _fileUploadSelectDefaultText = 'Please select an import document';
  showFileUploadClearBtn = undefined; // RA Component doesn't actually check for boolean, so "false" is undefined
  fileUploadSelectText: string;

  @ViewChild('fileDialog') _fileInput: ElementRef;
  validationDocument: File;

  @ViewChild('runAllBtn') _runAllBtn: ElementRef;
  @ViewChild('update') _updateBtn: ElementRef;

  @Output() uploadFile = new EventEmitter();
  @Input() fileExtentions: string;

  clearFile() {
    // Reset Loaded File
    this.fileUploadSelectText = this._fileUploadSelectDefaultText;
    this.showFileUploadClearBtn = undefined;
    this.validationDocument = undefined;
    this._fileInput.nativeElement.value = '';
  }

  onFileChange(event: Event) {
    this.errorMessage = [];
    const eventInput = event.target as HTMLInputElement;
    const files = eventInput.files;
    this.validationDocument = files[0];
    this.fileUploadSelectText = this.validationDocument.name;
    this.showFileUploadClearBtn = true;
    const splitStringArray = this.validationDocument.name.split(".");
    if (this.fileExtentions.includes(splitStringArray[1])) {
      this.uploadFile.emit(this.validationDocument);
    } else {
      this.errorMessage = ["Please upload a file with either of the following formats : " + this.fileExtentions];
    }
  }


}
