import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';
import { getValueOrDefault } from '@app/utilities/get-value-or-default';
import { DataTableColumn } from "@interfaces/data-table-column";
import { DataTableRow } from '@interfaces/data-table-row';
import { Distributor } from '@interfaces/distributor';
import { FlatDataTable } from '@interfaces/flat-data-table';
import { PointOfSale } from '@interfaces/point-of-sale';
import { StopSellDistributor } from '@interfaces/stopsell-distributor';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { StopsellPocketPanelComponent } from '@core/components/stopsell-pocket-panel/stopsell-pocket-panel.component';
import { ChannelManagerStopsellGridRow } from "@interfaces/channel-manager-stopsell-grid-row";
import { TradeUtilityService } from "@services/ttc/trade-utility.service";
import { StopSellType } from "@services/ttc/stop-sell-type";
import { Subscription, catchError, firstValueFrom } from 'rxjs';
import { ChannelManager } from "@interfaces/ttc/channel-managers";
import { DistributorsRequest } from "@interfaces/ttc/distributors-request";
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';

@Component({
  selector: 'app-stopsell-channel-manager',
  templateUrl: './stopsell-channel-manager-page.component.html',
  styleUrls: ['./stopsell-channel-manager-page.component.scss']
})
export class StopsellChannelManagerPageComponent implements OnInit {

  readonly JSON = JSON;

  title = "Stopsell - Channel Manager";
  type : string = StopSellType.CHANNEL_MANAGER;

  stopsellResponseLastOnData: any;
  stopsellResponseLastOffData: any;

  distributorList: Distributor[] = [];

  @ViewChild('stopSellPocketPanel') stopsellPocketPanelComponent: StopsellPocketPanelComponent;
  pocketPanelBodyStopsell: string[] = [];
  pocketPanelTitle = 'Product Type Stopsell';

  @ViewChild('dataTable') dataTable: FlatDataTableComponent;
  dataTableConfig: FlatDataTable;
  datum: Promise<DataTableRow[]>;
  rawTableDatum: any[] = [];

  @ViewChild('dynamicTabs') dynamicTabs: ElementRef;
  selectedTabIndex = 0;

  @ViewChild('cmSelect') cmSelect: ElementRef;
  channelManagerSelected: string;
  channelManagerSelectOptions: string[];
  channelManagers: ChannelManager[] = [];

  @ViewChild('destSelect') destinationSelectInput: ElementRef;
  destinationSelectOptions: string[] = [];
  destData: string[];
  destSelected = "";

  @ViewChild('productTypeSelect') productTypeSelectInput: ElementRef;
  prodTypeSelected = "";
  productTypeSelectOptions: string[] = [];

  @ViewChild('stopSellToggleBtn') stopSellToggleBtn: ElementRef;

  @ViewChild('remarksInput') remarksInput: ElementRef;
  remarksEntered = "";

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {

    this.populateChannelManagers();
    this.initializeDataTable();

  }

  private initializeDataTable() {
    this.dataTableConfig = {
      columns: [
        {
          label: 'On StopSell',
          field: 'stopsellStatus',
          type: 'status'
        },
        {
          label: 'Distributor Code',
          field: 'code',
          sortable: true
        },
        {
          label: 'Name',
          field: 'name',
          sortable: true
        },
        {
          label: 'Contract Active',
          field: 'active',
          type: 'boolean'
        }
      ],
      displayGrid: false,
      enableEditableRows: false,
      enableLazyScroll: true,
      enableQuickFilter: true,
      enableSelection: true,
      lockManualSelection: true,
      primaryKey: 'rowId',
      size: 'sm'
    };
  }

  private populateDataTable() {
    this.datum = Promise.resolve(this.rawTableDatum);
  }

  onDataTableLoaded() {
    this.dataTable.selectAllRows();
  }

  /**
   * Populates the channel manager dropdown with options from TUS.
   * @private
   */
  private populateChannelManagers() {

    this.activityService.show("Loading Channel Managers...");

    firstValueFrom(this.tus.getChannelManagers()).then((cmResponse) => {

      this.channelManagers = cmResponse;
      this.channelManagerSelectOptions = this.channelManagers.map(item => item.name);

    }).catch(reason => {
      this.logger.error('', { contextMsg: 'Failed to retrieve channel managers', reason });
    }).finally(() => {
      this.activityService.hide();
    });

  }

  /**
   * Populates destination dropdown with the TUS CM response.
   * @private
   */
  private populateDestinations() {

    const cm = this.channelManagers.find((cm) => {
      return cm.name === this.channelManagerSelected;
    });

    if(cm) {
      this.destinationSelectOptions = cm.propertyBrands.map((propertyBrand) => {
        return propertyBrand.code;
      });
    }

  }

  /**
   * Populates the data table with distributors.
   * @private
   */
  private populateDistributors() {

    // Call TUS to populate the distributors list
    const request: DistributorsRequest = {
      headers: {
        'X-Fresh-Data': 'false',
        requestorType: 'TTCSpa',
        requestorId: this.channelManagerSelected,
        propertyBrand: this.destSelected
      },
      params: {
        field: 'pos'
      }
    };

    this.activityService.show("Loading Distributors...");

    firstValueFrom( this.tus.getDistributors(request))
      .then(response => {

        this.distributorList = response;

        this.rawTableDatum = response.map((distributor, index) => {

          const rowItem: DataTableRow = {
            rowId: index,
            stopsellStatus: this.isPOSOnStopSell(distributor.propertyBrands[this.destSelected].pos),
            pos: distributor.propertyBrands[this.destSelected].pos ? (distributor.propertyBrands[this.destSelected].pos.length) : (0),
            name: distributor.name.value,
            code: distributor.name.code,
            active: distributor.active
          };

          return rowItem;

        });

      }).catch((reason) => {
      this.logger.error('', { contextMsg: 'Failed to retrieve distributors for product-type stop-sell', reason });
    }).finally(() => {
      this.populateDataTable();
      this.activityService.hide();
    });

  }

  /**
   * Determines if POS is on Stop-sell with matching criteria.
   * @param pos
   * @private
   */
  private isPOSOnStopSell(pos: PointOfSale[]): boolean {

    if(pos) {

      const foundPos = pos.find((posItem) => {
        return posItem.stopSells?.find((stopsellItem) => {

          const isProductType = stopsellItem.type === StopSellType.CHANNEL_MANAGER;

          return isProductType;

        });
      });

      return !!foundPos; // fast conversion to boolean

    } else {
      return false;
    }

  }

  private isDistributorOnStopSell(destination: string, distributor: Distributor) {
    const pos = getValueOrDefault(destination, {}, distributor?.propertyBrands)?.pos;
    if(pos) {
      return this.isPOSOnStopSell(pos);
    } else {
      return false;
    }
  }

  private mapToStopSell(selectedDataTableRows: DataTableRow[], filterToStopsellOn: boolean): StopSellDistributor[] {

    return selectedDataTableRows.map((row) => {

      // Get the distributor information by it's index in our data-table
      const distributor = this.distributorList[row.rowId];

      const isDistributorOnStopsell = this.isDistributorOnStopSell(this.destSelected, distributor);

      if(filterToStopsellOn === isDistributorOnStopsell) {
        const pos = distributor.propertyBrands ? distributor.propertyBrands[this.destSelected].pos : [];

        return {
          name: { value: distributor.name.code },
          pos: pos.map((item) => {
            return {
              id: item.id
            };
          })
        };

      } else {

        return undefined;

      }
    }).filter((distributor) => { return distributor; });

  }


  onStopsellToggleClicked() {

    this.pocketPanelTitle = `Modifying ${this.rawTableDatum.length} distributors for Channel Manager Stopsell`;
    this.pocketPanelBodyStopsell = [
      "Channel Manager : " + this.channelManagerSelected,
      "Destination : " + this.destSelected
    ];

    this.stopsellPocketPanelComponent.openRightPanel();
  }

  onSelectChannelManager($event: any) {
    this.channelManagerSelected = $event.detail.value;
    this.populateDestinations();

    // Reset everything when we change channel managers
    if(this.destSelected.length > 0) {
      this.destSelected = "";
      this.destinationSelectInput.nativeElement.value = "";
      this.prodTypeSelected = "";
      this.productTypeSelectInput.nativeElement.value = "";
      this.remarksEntered = "";
    }
  }


  onSelectDestination($event: any) {

    this.destSelected = $event.detail.value;

    if(this.destSelected.length > 0) {
      this.populateDistributors();
    }

  }

  onRemarksInput($event: any) {
    this.remarksEntered = $event.target.value;
  }

  onToggleStopsell() {

    const selectedData = this.dataTable.getSelectedData();

    this.activityService.show('Performing Product Type Stopsell...');

    // Map Distributor Stop Sell Request
    const selectedBranchDistributorsOnStopsell: StopSellDistributor[] = this.mapToStopSell(selectedData, false);
    const individualDistributorsOnStopsell = selectedBranchDistributorsOnStopsell.length > 0 ? ([{ distributors: selectedBranchDistributorsOnStopsell }]) : ([]);

    const selectedBranchDistributorsOffStopsell: StopSellDistributor[] = this.mapToStopSell(selectedData, true);
    const individualDistributorsOffStopsell = selectedBranchDistributorsOffStopsell.length > 0 ? ([{ distributors: selectedBranchDistributorsOffStopsell }]) : ([]);

    return (async () => {

      try {
        this.activityService.show(`Putting ${individualDistributorsOnStopsell.length} distributors on stop-sell...`);

        if(individualDistributorsOnStopsell.length > 0) {
          this.stopsellResponseLastOnData = await firstValueFrom(
            this.tus.updateStopSell(
              individualDistributorsOnStopsell,
              this.channelManagerSelected,
              this.destSelected,
              StopSellType.CHANNEL_MANAGER,
              true,
              this.remarksEntered
            )
          );
        } else {
          console.log('Skipping on stop-sell request, no valid entities found.');
        }

        this.activityService.show(`Taking ${individualDistributorsOffStopsell.length} distributors off stop-sell...`);

        if(individualDistributorsOffStopsell.length > 0) {
          this.stopsellResponseLastOffData = await firstValueFrom(
            this.tus.updateStopSell(
              individualDistributorsOffStopsell,
              this.channelManagerSelected,
              this.destSelected,
              StopSellType.CHANNEL_MANAGER,
              false,
              this.remarksEntered
            )
          );
        } else {
          console.log('Skipping off stop-sell request, no valid entities found.');
        }

      } catch (reason) {
        console.log(reason);
        this.logger.error('', { contextMsg: 'Failed to perform stop-sell request chain, impartial results possible', reason });
      } finally {
        this.activityService.hide();
      }

    })().then(() => {
      this.populateDistributors();
    });

  }

}
