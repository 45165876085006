<div class="validations-main">

  <app-validate-file
          #ticketListsFileUpload
          id="ticketListsFileUpload"
          (uploadFile)="uploadTicketValidationFiles($event)"
          [fileExtentions]="fileExtentions"
          [validationData]="ticketsData"
          [validationType]="validationType"
  />

  <div class="tabs-wrapper">
    <wdpr-tabs #validationTabs selected-tab-index="0" (tab-changed)="onTabChanged($event)">

      <wdpr-tab id="tab-product-codes">
        <wdpr-status-label class="validation-status"
                           [attr.kind]="getStatusType(productsIssues)"
                           [attr.text]="productsIssues">
        </wdpr-status-label>
        Product Codes
      </wdpr-tab>

      <wdpr-tab id="tab-product-details">
        <wdpr-status-label class="validation-status"
                           [attr.kind]="getStatusType(productCodeIssues)"
                           [attr.text]="productCodeIssues">

        </wdpr-status-label>
        Product Details
      </wdpr-tab>

      <wdpr-tab id="tab-product-prices">
        <wdpr-status-label class="validation-status"
                           [attr.kind]="getStatusType(pricesIssues)"
                           [attr.text]="pricesIssues">
        </wdpr-status-label>
        Product Prices
      </wdpr-tab>

      <div slot="body">

        <div data-tab-index="0" tabindex="0" labelledby="tab-product-codes" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table
                    #ticketsTable
            />
          </div>
        </div>

        <div data-tab-index="1" tabindex="1" labelledby="tab-product-details" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table
                    #ticketsTableProductDetails
            />
          </div>
        </div>

        <div data-tab-index="2" tabindex="2" labelledby="tab-product-prices" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table
                    #ticketsTableProductPrices
                    [config]=""
            />
          </div>
        </div>

      </div>

    </wdpr-tabs>
  </div>

</div>
