import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validations-page',
  templateUrl: './validations-page.component.html',
  styleUrls: ['./validations-page.component.scss']
})
export class ValidationsPageComponent implements OnInit {

  cards = [];

  ngOnInit(): void {
    this.cards = [
      {
        title: 'Channel Managers & Distributors',
        icon: 'di-leadership',
        url: '/web/validations/distributors',
        body: 'Execute a series of platform tests that verifies that expected distributors & channel managers exist in the system.'
      },
      {
        title: 'Tickets',
        icon: 'di-tickets',
        url: '/web/validations/tickets',
        body: 'Execute a series of platform tests that verifies the existences of expected ticket codes/skus.'
      },
      {
        title: 'Packages',
        icon: 'di-booking-rooms-packages',
        url: '/web/validations/packages',
        body: 'Execute a series of platform tests that verifies the existence of expected room packages.'
      }
    ];
  }

}
