<div class="validations-main">

  <app-validate-file id="ticketListsFileUpload" (uploadFile)="uploadPackageValidationFiles($event)"
  [fileExtentions]="fileExtentions" [validationData]="packageData"/>

  <div class="tabs-wrapper">
    <wdpr-tabs #validationTabs selected-tab-index="0" (tab-changed)="onTabChanged($event)">

      <wdpr-tab id="tab-package-details">
        <wdpr-status-label class="validation-status"
                           [attr.kind]="getStatusType(packageDetailsIssues)"
                           [attr.text]="packageDetailsIssues">
        </wdpr-status-label>
        Package Details
      </wdpr-tab>

      <wdpr-tab id="tab-package-price">
        <wdpr-status-label class="validation-status" [attr.kind]="getStatusType(packagePriceIssues)"
          [attr.text]="packagePriceIssues"></wdpr-status-label>
        Package Price
      </wdpr-tab>

      <wdpr-tab id="tab-source-market">
        <wdpr-status-label class="validation-status" [attr.kind]="getStatusType(sourceMarketIssues)"
          [attr.text]="sourceMarketIssues"></wdpr-status-label>
        Source Market
      </wdpr-tab>

      <div slot="body">

        <div data-tab-index="0" tabindex="0" labelledby="tab-product-codes" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table/>
          </div>
        </div>

        <div data-tab-index="1" tabindex="1" labelledby="tab-product-details" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table/>
          </div>
        </div>

        <div data-tab-index="2" tabindex="2" labelledby="tab-product-prices" class="page-main">
          <div class="grid-wrapper">
            <app-flat-data-table/>
          </div>
        </div>

      </div>

    </wdpr-tabs>

  </div>

</div>
