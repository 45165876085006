import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { WindowRefService } from '@services/window-ref/window-ref.service';
import { Router } from '@angular/router';
import { MyIdService } from "@services/myid/myid.service";
import { LoggerService } from '@wdpr/ra-angular-logger';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { RouteListenerService } from '@services/router-changes/route-listener.service';

@Component({
  selector: 'app-oidc-redirector',
  templateUrl: './oidc-redirector.component.html'
})
export class OidcRedirectorComponent implements AfterViewInit, OnDestroy {

  constructor(
    private windowRef: WindowRefService,
    private myIdService: MyIdService,
    private router: Router,
    private logger: LoggerService,
    private activityIndicator: ActivityIndicatorService,
    private routeListenerService: RouteListenerService
  ) {
  }

  ngAfterViewInit(): void {

    this.activityIndicator.show();

    const session = this.myIdService.processLoginOrRestoreSession();

    if (!session.valid) {

      if(this.routeListenerService.getPreviousUrl() === '/web/login') {

        this.myIdService.redirectToMyID('/web/home');

      } else {

        // Angular takes a moment for navigation events to finish up
        const previousUrl = this.routeListenerService.getPreviousUrl();
        this.myIdService.redirectToMyID(previousUrl ? (previousUrl) : ('/web/home'));

      }

    }

  }

  ngOnDestroy(): void {

    this.activityIndicator.hide();

  }

}
