import { StopsellDistributorPageComponent } from '@app/pages/stopsell-distributor-page/stopsell-distributor-page.component';
import { StopsellTicketsPageComponent } from '@app/pages/stopsell-tickets-page/stopsell-tickets-page.component';
import {
  FUNC_ABILITY_CHANNEL_MANAGER_READ,
  FUNC_ABILITY_CHANNEL_MANAGER_SYNC_READ,
  FUNC_ABILITY_CHANNEL_MANAGER_SYNC_WRITE,
  FUNC_ABILITY_CHANNEL_MANAGER_WRITE, FUNC_ABILITY_COMMUNICATIONS_READ, FUNC_ABILITY_COMMUNICATIONS_WRITE,
  FUNC_ABILITY_DISTRIBUTOR_READ,
  FUNC_ABILITY_DISTRIBUTOR_WRITE,
  FUNC_ABILITY_ORPHAN_TRANSACTIONS_READ, FUNC_ABILITY_ORPHAN_TRANSACTIONS_WRITE,
  FUNC_ABILITY_STOPSELL_READ,
  FUNC_ABILITY_STOPSELL_WRITE,
  FUNC_ABILITY_VALIDATIONS_READ,
  FUNC_ABILITY_VALIDATIONS_WRITE
} from '@app/constants/functional-abilities';
import { RouterModule, Routes } from '@angular/router';
import { ChannelManagerPageComponent } from '@app/pages/channel-manager-page/channel-manager-page.component';
import { ChannelManagersPageComponent } from '@app/pages/channel-managers-page/channel-managers-page.component';
import { CommunicationsPageComponent } from '@app/pages/communications-page/communications-page.component';
import { DistributorPageComponent } from '@app/pages/distributor-page/distributor-page.component';
import { DistributorsPageComponent } from '@app/pages/distributors-page/distributors-page.component';
import { ErrorPageComponent } from '@app/pages/error-page/error-page.component';
import { HomePageComponent } from '@app/pages/home-page/home-page.component';
import { KingswellShellComponent } from '@core/components/kingswell-shell/kingswell-shell.component';
import { StopsellChannelManagerPageComponent } from '@pages/stopsell-channel-manager-page/stopsell-channel-manager-page.component';
import { StopsellGlobalPageComponent } from '@pages/stopsell-global-page/stopsell-global-page.component';
import { StopsellPackageComponentPageComponent } from '@pages/stopsell-package-component-page/stopsell-package-component-page.component';
import { StopsellProductTypePageComponent } from '@pages/stopsell-product-type-page/stopsell-product-type-page.component';
import { SyncPackagesPageComponent } from '@pages/sync-packages-page/sync-packages-page.component';
import { MyIDGuard } from '@services/myid/myid-guard';
import { NgModule } from '@angular/core';
import { NotFoundPageComponent } from '@app/pages/not-found-page/not-found-page.component';
import { OidcRedirectorComponent } from '@core/components/oidc-redirector/oidc-redirector.component';
import { OrphanTransactionsPageComponent } from '@app/pages/orphan-transactions-page/orphan-transactions-page.component';
import { ProductsPageComponent } from '@app/pages/products-page/products-page.component';
import { StopsellLandingPageComponent } from '@app/pages/stopsell-landing-page/stopsell-landing-page.component';
import { StopsellRoomPackagePageComponent } from '@app/pages/stopsell-room-package-page/stopsell-room-package-page.component';
import { UnauthorizedPageComponent } from '@app/pages/unauthorized-page/unauthorized-page.component';
import { UploadDistributorPageComponent } from '@app/pages/upload-distributor-page/upload-distributor-page.component';
import { ValidationDistributorsPageComponent } from '@app/pages/validation-distributors-page/validation-distributors-page.component';
import { ValidationPackagesPageComponent } from '@app/pages/validation-packages-page/validation-packages-page.component';
import { ValidationTicketsPageComponent } from '@app/pages/validation-tickets-page/validation-tickets-page.component';
import { ValidationsPageComponent } from '@app/pages/validations-page/validations-page.component';



const routes: Routes = [
  { path: '', redirectTo: '/web/home', pathMatch: 'full' },
  {
    path: 'web', component: KingswellShellComponent, data: { breadcrumb: [{ name: 'Home', url: '/web/home' }] }, children: [
      {
        path: 'home', component: HomePageComponent,
        data: { breadcrumb: [{ name: 'Home', url: '/web/home' }] }
      },
      {
        path: 'login', component: OidcRedirectorComponent,
        data: { breadcrumb: [{ name: 'Home', url: '/web/home' }, { name: 'Login', url: '/web/login' }] }
      },
      {
        path: 'login/unauthorized', component: UnauthorizedPageComponent,
        data: { breadcrumb: [{ name: 'Home', url: '/web/home' }] }
      },
      {
        path: 'error', component: ErrorPageComponent,
        data: { breadcrumb: [{ name: 'Home', url: '/web/home' }] }
      },
      {
        path: 'not-found', component: NotFoundPageComponent,
        data: { breadcrumb: [{ name: 'Home', url: '/web/home' }] }
      },
      // Channel Manager Flow's
      {
        path: 'channel-managers', component: ChannelManagersPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [{ name: 'Home', url: '/web/home' }, { name: 'Channel Managers', url: '/web/channel-managers' }],
          abilities: [FUNC_ABILITY_CHANNEL_MANAGER_READ]
        }
      },
      {
        path: 'channel-managers/:channel-manager', component: ChannelManagerPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Channel Managers', url: '/web/channel-managers' },
            { name: ':channel-manager', url: '/web/channel-managers/:channel-manager' }
          ],
          abilities: [FUNC_ABILITY_CHANNEL_MANAGER_READ, FUNC_ABILITY_CHANNEL_MANAGER_WRITE]
        }
      },
      // Distributor's Flow
      {
        path: 'distributors', component: DistributorsPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [{ name: 'Home', url: '/web/home' }, { name: 'Distributors', url: '/web/distributors' }],
          abilities: [FUNC_ABILITY_DISTRIBUTOR_READ]
        }
      },
      {
        path: 'products', component: ProductsPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [{ name: 'Home', url: '/web/home' }, { name: 'Products', url: '/web/products' }],
          abilities: [FUNC_ABILITY_DISTRIBUTOR_READ]
        }
      },
      {
        path: 'distributors/:distributor', component: DistributorPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Distributors', url: '/web/distributors' },
            { name: ':distributor', url: '/web/distributors/:distributor' }
          ],
          abilities: [FUNC_ABILITY_DISTRIBUTOR_READ, FUNC_ABILITY_DISTRIBUTOR_WRITE]
        }
      },
      // Stop-Sell Flow
      {
        path: 'stop-sell', component: StopsellLandingPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' }
          ],
          abilities: [FUNC_ABILITY_STOPSELL_READ]
        },
      },
      {
        path: 'stop-sell/global', component: StopsellGlobalPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' },
            { name: 'Global', url: '/web/stop-sell/global' }
          ],
          abilities: [FUNC_ABILITY_STOPSELL_WRITE]
        }
      },
      {
        path: 'stop-sell/product-type', component: StopsellProductTypePageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [{ name: 'Home', url: '/web/home' },
          { name: 'Stop Sell', url: '/web/stop-sell' },
          { name: 'Product Type', url: '/web/stop-sell/product-type' }],
          abilities: [FUNC_ABILITY_STOPSELL_WRITE]
        }
      },
      {
        path: 'stop-sell/ticket-code', component: StopsellTicketsPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' },
            { name: 'Ticket Code', url: '/web/stop-sell/ticket-code' }
          ],
          abilities: [FUNC_ABILITY_STOPSELL_WRITE]
        }
      },
      {
        path: 'stop-sell/channel-manager', component: StopsellChannelManagerPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' },
            { name: 'Channel Manager', url: '/web/stop-sell/channel-manager' }
          ],
          abilities: [FUNC_ABILITY_STOPSELL_WRITE]
        }
      },
      {
        path: 'stop-sell/package-code', component: StopsellRoomPackagePageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' },
            { name: 'Room Package', url: '/web/stop-sell/package-code' }]
        }
      },
      {
        path: 'stop-sell/distributor', component: StopsellDistributorPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' },
            { name: 'Distributor', url: '/web/stop-sell/distributor' }]
        }
      },
      {
        path: 'stop-sell/package-component', component: StopsellPackageComponentPageComponent,
        canActivate: [MyIDGuard],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Stop Sell', url: '/web/stop-sell' },
            { name: 'Package Component', url: '/web/stop-sell/package-component' }]
        }
      },
      // Validation's Flow
      {
        path: 'validations', component: ValidationsPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Validations', url: '/web/validations' },
          ],
          abilities: [FUNC_ABILITY_VALIDATIONS_READ]
        }
      },
      {
        path: 'validations/tickets', component: ValidationTicketsPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Validations', url: '/web/validations' },
            { name: 'Tickets', url: '/web/validations/tickets' },
          ],
          abilities: [FUNC_ABILITY_VALIDATIONS_READ, FUNC_ABILITY_VALIDATIONS_WRITE]
        }
      },
      {
        path: 'validations/packages', component: ValidationPackagesPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Validations', url: '/web/validations' },
            { name: 'Packages', url: '/web/validations/packages' },
          ],
          abilities: [FUNC_ABILITY_VALIDATIONS_READ, FUNC_ABILITY_VALIDATIONS_WRITE]
        }
      },
      {
        path: 'validations/distributors', component: ValidationDistributorsPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Validations', url: '/web/validations' },
            { name: 'Distributors', url: '/web/validations/distributors' },
          ],
          abilities: [FUNC_ABILITY_VALIDATIONS_READ, FUNC_ABILITY_VALIDATIONS_WRITE]
        }
      },
      {
        path: 'upload/distributors', component: UploadDistributorPageComponent,
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Distributors Upload', url: '/manage/distributors/upload' }]
        }
      },
      {
        path: 'manage/orphan-transactions', component: OrphanTransactionsPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Orphan Transactions', url: '/web/manage/orphan-transactions' },
          ],
          abilities: [FUNC_ABILITY_ORPHAN_TRANSACTIONS_READ, FUNC_ABILITY_ORPHAN_TRANSACTIONS_WRITE]
        }
      },
      {
        path: 'manage/communications', component: CommunicationsPageComponent,
        canActivate: [ MyIDGuard ],
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Communications', url: '/web/manage/communications' },
          ],
          abilities: [FUNC_ABILITY_COMMUNICATIONS_READ, FUNC_ABILITY_COMMUNICATIONS_WRITE]
        }
      },
      {
        path: 'sync/packages', component: SyncPackagesPageComponent,
        data: {
          breadcrumb: [
            { name: 'Home', url: '/web/home' },
            { name: 'Sync', url: '/web/sync' },
            { name: 'Packages', url: '/web/sync/packages' },
          ],
          abilities: [FUNC_ABILITY_CHANNEL_MANAGER_SYNC_READ, FUNC_ABILITY_CHANNEL_MANAGER_SYNC_WRITE]
        }
      }
    ],
  },
  { path: '**', redirectTo: 'web/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
